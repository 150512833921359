@use '~@angular/material' as mat;
@import 'material-icons/iconfont/material-icons.scss';
@import url('https://rsms.me/inter/inter.css');
@import '~@angular/material/theming';
@import 'red-theme';
@import 'grey-theme';

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();
html{
margin:0;
}


$primary: (
    50 : #f6f6f6,
    100 : #f0f0f0,
    200 : #dedede,
    300 : #d0d0d0,
    400 : #858585,
    500 : #545454,
    600 : #404040,
    700 : #353535,
    800 : #242424,
    900 : #000000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$accent: (
    50 : #e7e8ea,
    100 : #c3c5cb,
    200 : #9c9fa9,
    300 : #747986,
    400 : #565c6c,
    500 : #383f52,
    600 : #32394b,
    700 : #2b3141,
    800 : #242938,
    900 : #171b28,
    A100 : #6d8cff,
    A200 : #3a64ff,
    A400 : #073cff,
    A700 : #0033ec,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);
$warn: (
    50 : #ffe8e3,
    100 : #ffc5b9,
    200 : #ff9f8b,
    300 : #ff795d,
    400 : #ff5c3a,
    500 : #ff3f17, // main red color
    600 : #ff3914,
    700 : #ff3111,
    800 : #ff290d,
    900 : #ff1b07,
    A100 : #ffffff,
    A200 : #fff5f4,
    A400 : #ffc4c1,
    A700 : #ffaca7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the default theme (same as the example above).
$app-primary: mat.define-palette($primary, 900);
$app-accent: mat.define-palette($accent);
$app-warn: mat.define-palette($warn);
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

@include mat.core();

// Include the default theme styles.
@include mat.all-component-themes($app-theme);
$custom-typography: mat.define-typography-config($font-family: "'Inter UI', sans-serif",
$display-4: mat.define-typography-level(112px, 112px, 300),
$display-3: mat.define-typography-level(56px, 56px, 400),
$display-2: mat.define-typography-level(45px, 48px, 400),
$display-1: mat.define-typography-level(34px, 40px, 400),
$headline: mat.define-typography-level(24px, 32px, 400),
$title: mat.define-typography-level(20px, 32px, 500),
$subheading-2: mat.define-typography-level(16px, 28px, 400),
$subheading-1: mat.define-typography-level(15px, 24px, 400),
$body-2: mat.define-typography-level(14px, 24px, 700),
$body-1: mat.define-typography-level(14px, 20px, 600),
$caption: mat.define-typography-level(12px, 20px, 400),
//$button: mat.define-typography-level(14px, 14px, 600), // Line-height must be unit-less fraction of the font-size.
$input: mat.define-typography-level(inherit, 1.125, 400));

a {
    font-weight: 600;
    text-decoration: none;
    color: mat.get-color-from-palette($primary, 900);
    position: relative;

    &:hover {
        text-decoration: underline;
        color: mat.get-color-from-palette($primary, 500);
    }
}
.disable-hover *:hover { color: white !important;}


button.mat-button,
button.mat-stroked-button,
button.mat-raised-button,
a.mat-button,
a.mat-stroked-button,
a.mat-raised-button {
    padding: 0 24px;
    border-radius: 160px;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
}
button.mat-button,
a.mat-button {
    &.mat-primary {
        &:hover {
            color: mat.get-color-from-palette($primary, 700);
            background-color: transparent;
        }
    }
}

button.mat-raised-button,
button.mat-mini-fab,
a.mat-mini-fab,
a.mat-raised-button {
    &.mat-primary {
        &:hover {
            background-color: mat.get-color-from-palette($primary, 500);
        }

        &[disabled] {
            background-color: mat.get-color-from-palette($primary, 100) !important;
            color: mat.get-color-from-palette($primary, 400) !important;
        }
    }
}

button.mat-stroked-button,
a.mat-stroked-button {
    &.mat-primary {
        &:hover {
            background-color: mat.get-color-from-palette($primary, 50);
        }
    }
}



$success-color: rgb(9, 167, 0);
$error-color: rgb(225, 0, 39);
$warn-color: rgb(226, 135, 0);
$none-color: rgba(38, 38, 38, 1);


.ca-font-size-150 {
    font-size: 1.5em;
}

mat-radio-button {
    padding-right: 5px;
}

html, body {
    height: 100%;
}

body {
    font-family: 'Inter UI', sans-serif;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-elevation{
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(43, 50, 75, 0.08), 0 2px 4px 0 rgba(43, 50, 75, 0.08);
    background-color: #ffffff;
    display: flex;
}

.help-icon.material-icons {
    cursor: pointer;
    vertical-align: bottom;
    :hover, :active {
        color: #03a6ff !important;
    }
}

.popper {
    z-index: 1;
    background-color: #555;
    color: white;
    font-size: 14px;
}


/**
 A set of global styles that cannot be handled by themes, because of Angular/Material
 problems.
 */

body {
    margin: 0 !important;
    padding: 0 !important;
    /* IE10+ */
    background-image: -ms-linear-gradient(top left, #E3E7ED 0%, #F2F6FB 70%);
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top left, #E3E7ED 0%, #F2F6FB 70%);
    /* Opera */
    background-image: -o-linear-gradient(top left, #E3E7ED 0%, #F2F6FB 70%);
    /* Webkit (Safari/Chrome 10) */
    background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0, #E3E7ED), color-stop(70, #F2F6FB));
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top left, #E3E7ED 0%, #F2F6FB 70%);
    /* W3C Markup */
    background-image: linear-gradient(to bottom right, #E3E7ED 0%, #F2F6FB 70%);
}

.repayment-tooltip-class {
    white-space: pre-line;
    max-width: 360px !important;
}

/**

   SNACKBARS

 */
.mat-snack-bar-container {
    max-width: 80vw !important;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.4);
}

/*  ------------------------------------- Fix   */
/* Fix a width of snackbars for HandSet modes */
.mat-snack-bar-handset .ca-snackbar {
    margin-bottom: 0 !important;
}

.mat-snack-bar-handset .mat-snack-bar-container {
    max-width: 100vw !important;
}
/*  ------------------------------------ FixEnd */

.ca-snackbar {
    margin-bottom: 5vh !important;
}

.ca-snackbar.ca-info {
    background-color: #2d354d !important;
    color: white !important;
}

.ca-snackbar.ca-error {
    background-color: #d50012 !important;
    color: white !important;
}

/* All snackbar action buttons have always white text */
.ca-snackbar .mat-button-wrapper {
    color: white !important;
}


@font-face {
    font-family: 'ca-icons';
    src:  url('assets/fonts/ca-icons.eot?imggbd');
    src:  url('assets/fonts/ca-icons.eot?imggbd#iefix') format('embedded-opentype'),
    url('assets/fonts/ca-icons.ttf?imggbd') format('truetype'),
    url('assets/fonts/ca-icons.woff?imggbd') format('woff'),
    url('assets/fonts/ca-icons.svg?imggbd#ca-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="ca-icon-"], [class*=" ca-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'ca-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ca-icon-picture:before {
    content: "\e903";
}
.ca-icon-heatpump:before {
    content: "\e900";
}
.ca-icon-photovoltaic:before {
    content: "\e901";
}
.ca-icon-batterystorage:before {
    content: "\e902";
}
.ca-icon-ventilation:before {
    content: "\e904";
}
.ca-icon-independence:before {
    content: "\e905";
}
.ca-icon-balance:before {
    content: "\e906";
}
.ca-icon-efficiency:before {
    content: "\e907";
}
.ca-icon-kfw40:before {
    content: "\e909";
}
.ca-icon-kfw40plus:before {
    content: "\e908";
}
.ca-icon-enev2016:before {
    content: "\e90b";
}
.ca-icon-kfw55:before {
    content: "\e90a";
}

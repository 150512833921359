@use '~@angular/material' as mat;
@import '~@angular/material/theming';

$red-primary: (
    50 : #fee5e4,
    100 : #fcbdbb,
    200 : #fa918d,
    300 : #f8655f,
    400 : #f7443d,
    500 : #f5231b,
    600 : #f41f18,
    700 : #f21a14,
    800 : #f01510,
    900 : #ee0c08,
    A100 : #ffffff,
    A200 : #ffe3e3,
    A400 : #ffb1b0,
    A700 : #ff9796,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$red-accent: (
    50 : #ecedee,
    100 : #cfd1d5,
    200 : #afb2ba,
    300 : #8e939e,
    400 : #767c89,
    500 : #5e6574,
    600 : #565d6c,
    700 : #4c5361,
    800 : #424957,
    900 : #313744,
    A100 : #96b9ff,
    A200 : #6397ff,
    A400 : #3075ff,
    A700 : #1664ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$red-warn: (
    50 : #fbe3e3,
    100 : #f5b8b8,
    200 : #ee8989,
    300 : #e75a5a,
    400 : #e23636,
    500 : #dd1313,
    600 : #d91111,
    700 : #d40e0e,
    800 : #cf0b0b,
    900 : #c70606,
    A100 : #fff0f0,
    A200 : #ffbdbd,
    A400 : #ff8a8a,
    A700 : #ff7070,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$red-primary-palette: mat.define-palette($red-primary);
$red-accent-palette: mat.define-palette($red-accent);
$red-warn-palette: mat.define-palette($red-warn);

$red-light-theme: mat.define-light-theme($red-primary-palette, $red-accent-palette, $red-warn-palette);

.red-light-theme {
    @include mat.all-component-themes($red-light-theme);

    .mat-stepper-horizontal, .mat-stepper-vertical {
        background-color: transparent;
    }
}

@use '~@angular/material' as mat;
@import '~@angular/material/theming';

$grey-primary: (
    50 : #ecedee,
    100 : #cfd1d5,
    200 : #afb2ba,
    300 : #8e939e,
    400 : #767c89,
    500 : #5e6574,
    600 : #565d6c,
    700 : #4c5361,
    800 : #424957,
    900 : #313744,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$grey-accent: (
    50 : #ecedee,
    100 : #cfd1d5,
    200 : #afb2ba,
    300 : #8e939e,
    400 : #767c89,
    500 : #5e6574,
    600 : #565d6c,
    700 : #4c5361,
    800 : #424957,
    900 : #313744,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$grey-warn: (
    50 : #fbe3e3,
    100 : #f5b8b8,
    200 : #ee8989,
    300 : #e75a5a,
    400 : #e23636,
    500 : #dd1313,
    600 : #d91111,
    700 : #d40e0e,
    800 : #cf0b0b,
    900 : #c70606,
    A100 : #fff0f0,
    A200 : #ffbdbd,
    A400 : #ff8a8a,
    A700 : #ff7070,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$grey-primary-palette: mat.define-palette($grey-primary);
$grey-accent-palette: mat.define-palette($grey-accent);
$grey-warn-palette: mat.define-palette($grey-warn);

$grey-light-theme: mat.define-light-theme($grey-primary-palette, $grey-accent-palette, $grey-warn-palette);

.grey-light-theme {
    @include mat.all-component-themes($grey-light-theme);

    .mat-stepper-horizontal, .mat-stepper-vertical {
        background-color: transparent;
    }
}
